<template>

    <div>
    <hb-modal v-model="dialog" show-help-link size="large" :title="selected && selected.id ? (isOPS? 'Edit Coverage Plan' : 'Edit Coverage') :  (isOPS ? 'Add Coverage Plan' : 'Add Coverage')" @close="$emit('close')">
        <template v-slot:content>
            <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>

            <hb-form label="Name" large required>
                <HbTextField
                    :readonly="isPropertyLevel"
                    id="name"
                    name="name"
                    v-model="insurance.name"
                    v-validate="'required|max:45'"
                    data-vv-name="name"
                    data-vv-as="Name"
                    :error="errors.first('name')"
                    placeholder="Enter Name"
                    width="362px"
                />
            </hb-form>

            <hb-form v-if="!isPropertyLevel" label="Description" large>
                <HbTextField
                    id="description"
                    name="description"
                    v-model="insurance.description"
                    v-validate="'max:1000'"
                    data-vv-name="description"
                    data-vv-as="description"
                    :error="errors.first('description')"
                    placeholder="Enter a description"
                    width="362px"
                />
            </hb-form>

            <hb-form v-if="!isPropertyLevel" label="Space Type" large required>
                <HbSelect
                    id="unit_type_id"
                    name="unit_type_id"
                    item-text="display_name"
                    item-value="unit_type_id"
                    v-model="insurance.unit_type_id"
                    :items="getUnitTypes('have_coverage')"
                    v-validate="'required'"
                    data-vv-name="unit_type_id"
                    data-vv-as="Unit Type"
                    :error="errors.has('unit_type_id')"
                    placeholder="Select Space Type"
                    width="362px"
                />
            </hb-form>

            <hb-form v-if="!isPropertyLevel" :label="isOPS ? 'Monthly Cost/Premium':'Monthly Premium'" large required>
                <v-row no-gutters class="pa-0 mb-n3">
                    <HbTextField
                        width="302px"
                        id="premium_value"
                        name="premium_value"
                        v-model="insurance.premium_value"
                        v-validate="'required|decimal:2|min_value:.01|max_value:9999'"
                        data-vv-name="premium_value"
                        data-vv-as="premium value"
                        :error="errors.first('premium_value') || errors.first('premium_type')"
                        placeholder="Enter Monthly Premium"
                    />

                    <HbSelect
                        small
                        :clearable="false"
                        id="premium_type"
                        name="premium_type"
                        v-model="insurance.premium_type"
                        :items="premiumTypes"
                        v-validate="'required'"
                        data-vv-name="premium_type"
                        data-vv-as="premium type"
                        class="ml-2 px-2"
                        :error="errors.first('premium_value') || errors.first('premium_type')"
                    />
                </v-row>
            </hb-form>

            <hb-form label="Coverage" large v-if="!isPropertyLevel" required>
                <hb-TextField
                    id="coverage"
                    name="coverage"
                    v-model="insurance.coverage"
                    v-validate="'required|decimal:2|min_value:.01|max_value:999999'"
                    data-vv-name="coverage"
                    data-vv-as="coverage"
                    :error="errors.first('coverage')"
                    placeholder="Coverage"
                    width="362px"
                >
                    <template v-slot:prepend-inner> $ </template>
                </hb-TextField>
            </hb-form>

            <hb-form label="Deductible" large v-if="!isPropertyLevel">
                <hb-TextField
                    id="deductible"
                    name="deductible"
                    v-model="insurance.deductible"
                    v-validate="'decimal:2|min_value:0|max_value:99999'"
                    data-vv-name="deductible"
                    data-vv-as="deductible"
                    :error="errors.first('deductible')"
                    placeholder="Deductible"
                    width="362px"
                >
                    <template v-slot:prepend-inner> $ </template>
                </hb-TextField>
            </hb-form>

            <hb-form label="Taxable" large>
                <hb-radio-group row v-model="insurance.taxable" class="pa-0 mb-n3">
                    <HbRadio label="Yes" :value="1"/>
                    <HbRadio label="No" :value="0" class="pl-3" />
                </hb-radio-group>
            </hb-form>

            <hb-form label="Prorate In" large>
                <hb-radio-group row v-model="insurance.prorate" class="pa-0 mb-n3">
                    <HbRadio label="Yes" :value="1"/>
                    <HbRadio label="No" :value="0" class="pl-3" />
                </hb-radio-group>
            </hb-form>

            <hb-form label="Prorate Out" large>
                <hb-radio-group row v-model="insurance.prorate_out" class="pa-0 mb-n3">
                    <HbRadio label="Yes" :value="1"/>
                    <HbRadio label="No" :value="0" class="pl-3" />
                </hb-radio-group>
            </hb-form>

            <hb-form v-if="available_accounts.length > 0 && !isOPS" label="GL Account" large >
                <hb-autocomplete
                    :disabled="!hasPermission('assign_gl_accounts')"
                    name="gl_account_id"
                    hide-details
                    label="Select an Account"
                    :items="available_accounts"
                    :clearable="false"
                    v-validate="`${incomeAccountRequired ?  'required' : ''}`"
                    :error-messages="errors.first('gl_account_id')? 'Income account is required as accounting service is enabled' : ''"
                    data-vv-as="gl account"
                    item-value="id"
                    item-text="name"
                    v-model="insurance.income_account_id">
                    width="362px"
                    <template v-slot:selection="{ item }">
                        {{item.code}} : {{item.name}}
                    </template>
                    <template v-slot:item="{ item }">
                        {{item.code}} : {{item.name}}
                    </template>
                </hb-autocomplete>
            </hb-form>

            <enable-properties-field
                    v-if="!isPropertyLevel"
                    @selectedProperties="selectedProperties"
                    :selectedPropertiesList.sync="productProperties"
            />
        </template>
        <template v-slot:actions>
            <hb-btn color="primary" :disabled="isLoading($options.name)" @click="save">
                <span v-if="selected && selected.id">Save</span>
                <span v-else>Add</span>
            </hb-btn>
            <span v-show="isLoading($options.name)" >
                <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
            </span>
        </template>
    </hb-modal>

    <income-account-alert
    v-if="showAlertDialog && !isPropertyLevel"
    v-model="showAlertDialog"
    @confirm="confirmAlert"
    @close="showAlertDialog = false">
    </income-account-alert>
    </div>

</template>


<script type="text/babel">
    import Status from '../includes/Messages.vue';
    import Loader from '../assets/CircleSpinner.vue';
    import api from '../../assets/api.js';
    import IncomeAccountAlert from './IncomeAccountAlertModal/IncomeAccountAlert.vue'
    import EnablePropertiesField from "./Common/EnablePropertiesField.vue";
    import { cloneDeep } from 'lodash';
    import Dropdown from '../assets/Dropdown.vue';
    import { mapGetters , mapActions } from 'vuex';

    import { notificationMixin } from "../../mixins/notificationMixin.js";

    export default {

        name: "EditInsurance",
        mixins:[notificationMixin],
        data() {
            return {
                open: '',
                insurance:{
                    id: '',
                    coverage: '',
                    deductible: '',
                    premium_value: '',
                    premium_type: '$',
                    recurring: 1,
                    prorate: 0,
                    prorate_out: 0,
                    name:'',
                    description: '',
                    taxable: 0,
                    unit_type_id:'0'
                },
                premiumTypes: ['$','%'],
                isPropertyLevel : false,
                productProperties: [],
                accounts: [],
                showAlertDialog: false,
                alertConfirmed : false,
            }
        },
        components:{
            Loader,
            Status,
            Dropdown,
            IncomeAccountAlert,
            EnablePropertiesField,
        },
        props:[
            'selected',
            'facilityID',
            'productLevel',
            'value',
            'isOPS'
        ],
        async created(){

            if ((this.productLevel && this.productLevel == 'Property') || this.facilityID) {
                this.isPropertyLevel = true;
                if (this.isOPS && this.productLevel == 'Property' && !this.selected) {
                        this.isPropertyLevel = false;
                    }
            }
            if(this.selected && this.selected.id){
                this.insurance = JSON.parse(JSON.stringify(this.selected));
                this.productProperties = this.insurance.Properties ? this.insurance.Properties.map(val => val.id) : [];
            } 

            await this.fetchAccounting();
            // await this.fetchAccountingType();
            await this.getAccountingType();
            // await this.getAccountingType({ isInsurance : true })

        },
        computed:{
            ...mapGetters({
                propertiesList: 'propertiesStore/filtered',
                onboardingData: 'onboardingTabsStore/getOnboardingData',
                hasPermission: 'authenticationStore/rolePermission',
                incomeAccountRequired: 'accountingStore/incomeAccountRequired',
                recordCountWithNullIncomeAccount: 'accountingStore/recordCountWithNullIncomeAccount',
            }),
            dialog: {
                get () {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value)
                }
            },
            income_accounts(){
                return this.accounts && this.accounts.filter(a => a.category && a.category.toLowerCase() === 'income');
            },
            expense_accounts(){
                return this.accounts && this.accounts.filter(a => a.category && a.category.toLowerCase() === 'expense');
            },
            liability_accounts(){
                return this.accounts && this.accounts.filter(a => a.category && a.category.toLowerCase() === 'liability');
            },
            concession_accounts(){
                return this.accounts && this.accounts.filter(a => a.category && a.account_subtype.toLowerCase() === 'concessions');
            },
            available_accounts(){
              return [...this.income_accounts,...this.expense_accounts,...this.liability_accounts];
            },
        },
        methods:{
            ...mapActions({
                getAccountingType : 'accountingStore/getAccountingType',
            }),

            confirmAlert() {
                this.alertConfirmed = true;
                this.showAlertDialog = false;
                this.save()
            },

            selectedProperties(properties) {
                this.productProperties = cloneDeep(properties);
            },

            async save(){

	            this.validate(this).then(status => {
			        if(!status) return;

                    if(!this.isLoading(this.$options.name)){

                        this.errorClear(this.$options.name);
                        this.startLoading(this.$options.name);

                        let data = Object.assign({}, this.insurance);
                        data.Properties = this.productProperties && this.productProperties.map(val => {
                            return {id: val};
                        });

                        let propertyLevelData = {
                            taxable: this.insurance.taxable,
                            recurring: this.insurance.recurring,
                            prorate: this.insurance.prorate,
                            prorate_out: this.insurance.prorate_out,
                            income_account_id: this.insurance.income_account_id
                        }

                        // added by BCT
                        if(this.isOPS && this.onboardingData.propertyData.length > 0 && !this.selected){
                            data.Properties = [{id: this.onboardingData.propertyData[0].property_id}] 
                        }

                        if(this.insurance.id){
                            let path = this.isPropertyLevel ? api.PROPERTIES + this.facilityID + '/products/' + this.insurance.id : api.INSURANCE + this.insurance.id;
                            api.put(this, path , this.isPropertyLevel ? propertyLevelData : data).then(r => {
                                this.$emit('refetch');
                                this.$emit('close');
                                this.$emit('showSuccess', this.insurance.name, true);
                            }).catch(error => {
                                this.showMessageNotification({ description: error });
                            });
                        } else {
                            api.post(this, api.INSURANCE, data).then(r => {
                                this.$emit('refetch');
                                this.$emit('close');
                                this.$emit('showSuccess', this.insurance.name);
                            }).catch(error => {
                                this.showMessageNotification({ description: error });
                            });

                        }

                    }
                })

            },
            away(){
                return this.openSuggestion = false;
            },
            async fetchAccounting(){
                let p = await api.get(this, api.ACCOUNTING + 'accounts');
                this.accounts = p.accounts || [];
            }
        },

    }


</script>

<style scoped>
    .mrl--25px{
        margin-left: -24px;
        margin-right: -24px;
    }
    .key-label{
      background: #F9FAFB;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: #101318;
    }
    .top-border{
        border-top: 1px solid #DFE3E8;
    }
    .simple-error{
        border: 1px solid #D91E18;
    }
    @media (max-height : 775px) {
        .scroll-able-div{
            max-height: 500px;
            overflow: scroll;
        }
    }
    .overflow-text {
        overflow-wrap: anywhere;
    }
    .scrollable-div{
        max-height: 300px;
        overflow: auto;
    }
</style>

<style>
    .select-items.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot {
        padding: 0 4px;
    }
    .insurance-input .theme--light.v-icon {
        color: rgba(0, 0, 0, 0.54);
    }
</style>
